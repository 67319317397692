.homePageContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background: #f7f7f7;

  .headerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
    margin-top: 2.49rem;
    padding: calc(var(--nav-height) + var(--navbar-margin-top)) 0 0 0;

    @media screen and (min-width: 730px) {
      margin-top: 4.34rem;
      margin-bottom: 2.52rem;
      padding: calc(var(--nav-height) + var(--navbar-margin-top)) 0 0 0;
    }

    @media screen and (min-width: 1000px) {
      margin-top: 5.33rem;
      margin-bottom: 3.09rem;
      padding: calc(var(--nav-height) + var(--navbar-margin-top)) 0 0 0;
    }

    @media screen and (min-width: 1400px) {
      margin-top: 7.35rem;
      margin-bottom: 4.35rem;
      padding: calc(var(--nav-height) + var(--navbar-margin-top)) 0 0 0;
    }

    @media screen and (min-width: 1700px) {
      margin-top: 7.5rem;
      // margin-bottom: 0;
      padding: calc(var(--nav-height) + var(--navbar-margin-top)) 0 0 0;
    }

    .header {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 1.16rem;
      padding: 0 1rem;
      z-index: 1;

      @media screen and (min-width: 730px) {
        padding: 0 6.95rem;
      }

      @media screen and (min-width: 1000px) {
        gap: 1.42rem;
        padding: 0 8.54rem;
      }

      @media screen and (min-width: 1400px) {
        gap: 2rem;
        padding: 0 11.5rem;
      }

      @media screen and (min-width: 1700px) {
        padding: 0 26.15rem;
      }

      .heading {
        font-weight: 700;
        font-size: 1.5rem;
        color: #383434;
        line-height: 2.125rem;
        text-align: center;

        @media screen and (min-width: 730px) {
          font-size: 2.31rem;
          line-height: 3.25rem;
        }

        @media screen and (min-width: 1000px) {
          font-size: 2.84rem;
          line-height: 4rem;
        }

        @media screen and (min-width: 1400px) {
          font-size: 4rem;
          line-height: 5.625rem;
        }

        @media screen and (min-width: 1700px) {
        }

        span:nth-child(1) {
          color: #7b29ff;
        }
        span:nth-child(2) {
          color: #3a6bff;
        }
        span:nth-child(3) {
          color: #22aeff;
        }
      }
      .subHeading {
        font-family: "Inter";
        font-weight: 400;
        font-size: 1.125rem;
        line-height: normal;
        color: #637D92;
        text-align: center;

        @media screen and (min-width: 730px) {
          font-size: 1.5rem;
          line-height: 1.32rem;
        }

        @media screen and (min-width: 1000px) {
          font-size: 1.75rem;
        }

        @media screen and (min-width: 1400px) {
          font-size: 2rem;
          line-height: 2.5rem;
        }
      }

      .learnMoreBtnContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .learnMoreBtn {
          border: none;
          outline: none;
          background: #0b0a0a;
          color: #fff;
          padding: 0.67rem 1.349rem;
          border-radius: 41rem;
          font-size: 0.78rem;
          line-height: 1.125rem;
          font-weight: 600;

          @media screen and (min-width: 730px) {
            padding: 0.58rem 1.17rem;
            font-size: 0.68rem;
            border-radius: 36rem;
          }

          @media screen and (min-width: 1000px) {
            border-radius: 44rem;
            font-size: 0.84rem;
            line-height: 1.2rem;
            padding: 0.72rem 1.44rem;
          }

          @media screen and (min-width: 1400px) {
            padding: 1.01rem 2.025rem;
            font-size: 1.18rem;
            line-height: 1.68rem;
          }

          @media screen and (min-width: 1700px) {
          }
        }
      }
    }
    .backgroundImg {
      width: 100%;
      object-fit: contain;
      margin-top: -2%;
    }
  }

  .cardsContainer {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    // display: flex;
    // flex-direction: column;
    padding: 1rem;
    margin-bottom: 2.5rem;
    gap: 2rem;

    @media screen and (min-width: 730px) {
      padding: 0 2.25rem;
      gap: 1.63rem;
      margin-bottom: 3.48rem;
    }

    @media screen and (min-width: 1000px) {
      padding: 0 2.88rem;
      gap: 2rem;
      margin-bottom: 4.27rem;
    }

    @media screen and (min-width: 1400px) {
      padding: 0 10.5rem;
      gap: 2.5rem;
      margin-bottom: 6rem;
    }

    @media screen and (min-width: 1700px) {
      padding: 0 18.75rem;
    }

    .card {
      width: 100%;
      display: flex;
      flex-direction: column-reverse;
      align-items: flex-start;
      justify-content: space-between;
      padding: 1rem;
      border-radius: 1rem;
      gap: 1.5rem;

      @media screen and (min-width: 730px) {
        flex-direction: row;
        padding: 0;
        gap: 0;
        border-radius: 1.14rem;
      }

      @media screen and (min-width: 1000px) {
        border-radius: 1.422rem;
      }

      @media screen and (min-width: 1400px) {
        border-radius: 2rem;
      }

      .cardTextcontainer {
        display: flex;
        flex-direction: column;
        // justify-content: space-between;
        width: 100%;
        height: 100%;
        gap: 1.5rem;

        @media screen and (min-width: 730px) {
          width: 45%;
          padding: 2.32rem 0 2.32rem 2.32rem;
          justify-content: space-between;
          // gap:0;
          gap: 1.81rem;
        }

        @media screen and (min-width: 1000px) {
          padding: 2.84rem 0 2.84rem 2.84rem;
          gap: 2.2rem;
        }

        @media screen and (min-width: 1400px) {
          padding: 4rem 0 4rem 4rem;
          gap: 3.21rem;
        }
        @media screen and (min-width: 1700px) {
        }

        .cardHeader {
          display: flex;
          flex-direction: column;
          gap: 1rem;

          @media screen and (min-width: 730px) {
            gap: 1.01rem;
          }

          @media screen and (min-width: 1000px) {
            gap: 1.24rem;
          }

          @media screen and (min-width: 1400px) {
            gap: 1.75rem;
          }
          @media screen and (min-width: 1700px) {
          }

          .title {
            font-family: "Inter";
            font-weight: 700;
            font-size: 1.5rem;
            line-height: 2.125rem;

            @media screen and (min-width: 730px) {
              font-size: 1.59rem;
              line-height: 2.244rem;
            }

            @media screen and (min-width: 1000px) {
              // text-wrap: nowrap;
              font-size: 1.95rem;
              line-height: 2.75rem;
            }

            @media screen and (min-width: 1400px) {
              font-size: 2.75rem;
              line-height: 3.875rem;
            }

            @media screen and (min-width: 1700px) {
            }

            span {
              background: linear-gradient(90deg, #3769e9 54.3%, #ff634a 73.88%);
              background-clip: text;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }

          .subTitle {
            font-family: "Inter";
            font-size: 0.875rem;
            line-height: 1.375rem;

            @media screen and (min-width: 730px) {
              font-size: 0.579rem;
              line-height: 0.94rem;
            }

            @media screen and (min-width: 1000px) {
              font-size: 0.711rem;
              line-height: 1.15rem;
            }

            @media screen and (min-width: 1400px) {
              font-size: 1rem;
              line-height: 1.625rem;
            }
            @media screen and (min-width: 1700px) {
            }
          }
        }

        .cardBtn {
          color: #fff;
          font-weight: 600;
          font-size: 0.66rem;
          line-height: 1rem;
          background: #1463ff;
          padding: 0.5rem 0.67rem;
          border: none;
          border-radius: 0.7rem;

          @media screen and (min-width: 730px) {
            font-size: 0.57rem;
            line-height: 0.86rem;
            padding: 0.43rem 0.58rem;
            border-radius: 0.61rem;
          }

          @media screen and (min-width: 1000px) {
            font-size: 0.71rem;
            line-height: 1.066rem;
            padding: 0.5rem 0.71rem;
            border-radius: 0.75rem;
          }

          @media screen and (min-width: 1400px) {
            font-size: 1rem;
            line-height: 1.5rem;
            padding: 0.75rem 1rem;
          }

          @media screen and (min-width: 1700px) {
            font-size: 1rem;
            line-height: 1.5rem;
          }
        }

        .cardBtn:hover{
          box-shadow: 0px 4px 16px -2px rgba(20, 99, 255, 0.10), 0px 4px 12px -4px rgba(20, 99, 255, 0.20);
          background: #1154D9;
        }
      }

      .cardImgContainer {
        width: 100%;
        // height: 100%;
        img {
          width: 100%;
          object-fit: contain;
        }

        @media screen and (min-width: 730px) {
          width: 45%;
        }
      }
    }

    .card:hover{
      box-shadow: 0px 0px 49px 4px rgba(158,153,158,1);
    }
  }
  .card:nth-child(1) {
    background: linear-gradient(180deg, #000 -46.44%, #201f25 207.35%);

    .title {
      color: #f1f3fc;
    }
    .subTitle {
      color: #cfcece;
    }

    .cardImgContainer {
      @media screen and (min-width: 1400px) {
        width: 56%;
        z-index: 0;
        margin-left: -35%;
      }

      @media screen and (min-width: 1700px) {
        width: 45%;
        z-index: 0;
        margin-left: 0;
      }
    }
  }
  .card:nth-child(2) {
    background: linear-gradient(270deg, #dde9ff -33.97%, #fefeff 78.4%);

    .title {
      color: #172b4d;
    }
    .subTitle {
      color: #626f86;
    }
    .cardTextcontainer {
      @media screen and (min-width: 730px) {
        width: 44%;
        z-index: 2;
      }

      @media screen and (min-width: 1400px) {
        width: 44%;
        z-index: 2;
        // margin-left: -25%;
      }
    }
    .cardImgContainer {
      @media screen and (min-width: 730px) {
        width: 56%;
        z-index: 0;
      }

      @media screen and (min-width: 1400px) {
        width: 65%;
        z-index: 0;
        margin-left: -35%;
      }

      @media screen and (min-width: 1700px) {
        width: 56%;
        z-index: 0;
        margin-left: 0;
      }
    }
  }
}
