* {
  transition: all 200ms ease-in-out;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #ffffff;
}

body {
  width: 100%;
  line-height: normal;
  overflow-x: hidden;
  max-width: 2500px;
}

a {
  text-decoration: none;
}

button {
	font-family: "Plus Jakarta Sans";
  cursor: pointer;
}

:root {
  --navbar-margin-top: 1.88rem;

  @media screen and (min-width: 730px) {
    --navbar-margin-top: 1.16rem;
  }
  @media screen and (min-width: 1000px) {
    --navbar-margin-top: 1.42rem;
  }
  @media screen and (min-width: 1400px) {
    --navbar-margin-top: 2rem;
  }

  @media screen and (min-width: 1700px) {
    --navbar-margin-top: 2rem;
  }
}
