.footer {
  width: 100%;
  max-width: 2500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem 1.06rem;
  position: relative;
  overflow: hidden;
  background: black;
  gap: 3.5rem;

  @media screen and (min-width: 730px) {
    gap: 0;
    padding: 2.60625rem 4.8505rem;
    gap: 2.89rem;
  }

  @media screen and (min-width: 1024px) {
    padding: 3.2rem 5.95556rem;
    gap: 3.58rem;
  }

  @media screen and (min-width: 1400px) {
    padding: 4.5rem 10.5rem;
    gap: 5rem;
  }

  @media screen and (min-width: 1700px) {
    // padding: 6rem 11.16669rem;
    padding: 4.5rem 23.39rem;
    
    gap: 6.67rem;
  }

  .footerHeader {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    //align-items: center;

    @media screen and (min-width: 730px) {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .headerLogoContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    z-index: 1;
    gap: 1.6rem;

    @media screen and (min-width: 320px) {
      display: flex;
      justify-content: space-between;
      gap: 1.6rem;
    }
    @media screen and (min-width: 730px) {
      flex-direction: column;
      justify-content: center;
      width: 100%;
    }
    @media screen and (min-width: 1000px) {
      align-items: flex-start;
      width: 100%;
    }
    @media screen and (min-width: 1400px) {
      width: 100%;
      // gap: 2rem;
    }
    @media screen and (min-width: 1700px) {
      gap: 2rem;
    }
  }
  .zirohLogo {
    width: auto;
    height: 1.14rem;

    @media screen and (min-width: 730px) {
      display: none;
      height: 1.14rem;
    }

    @media screen and (min-width: 730px) {
      display: flex;
      height: 1.39rem;
    }

    @media screen and (min-width: 1400px) {
      display: flex;
      height: 1.96rem;
    }
  }

  .linksChildContainer {
    display: flex;
    flex-direction: column;
    gap: 0.87rem;
    justify-content: space-between;
    width: 100%;

    @media screen and (min-width: 730px) {
      flex-direction: column;
      justify-content: space-between;

    }

    @media screen and (min-width: 1000px) {
      	gap: 1.07rem;
    }

    @media screen and (min-width: 1400px) {
      justify-content: space-between;
      gap: 1.5rem;
    }
  }
  
  .linksHeader {
    color: #8590a2;
    font-weight: 500;
    font-size: 0.75rem;
    cursor: pointer;

    @media screen and (min-width: 730px) {
      font-size: 0.65rem;
    }
    @media screen and (min-width: 1000px) {
      font-size: 0.85rem;
    }

    @media screen and (min-width: 1400px) {
      font-size: 1.125rem;
    }

    @media screen and (min-width: 1700px) {
      //   font-size: 1.5rem;
    }
  }
  .linksContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 1.5rem;

    @media screen and (min-width: 730px) {
      gap: 0;
    }

    a {
      text-decoration: none;
      color: #e8efff;
      font-weight: 500;
      font-size: 0.75rem;
      cursor: pointer;
      width: 100%;

      @media screen and (min-width: 730px) {
        font-size: 0.65rem;
      }
      @media screen and (min-width: 1000px) {
        font-size: 0.85rem;
      }

      @media screen and (min-width: 1400px) {
        font-size: 1.125rem;
      }

      @media screen and (min-width: 1700px) {
        // font-size: 1.5rem;
      }
    }
  }

  .privacyMediaContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 1.5rem;
    // padding-bottom: 2rem;
    z-index: 1;

    @media screen and (min-width: 550px) {
      // flex-direction: column;
      gap: 1rem;
    }

    @media screen and (min-width: 730px) {
      flex-direction: row;
      gap: 0.87rem;
    }
    @media screen and (min-width: 1000px) {
      flex-direction: row;
      gap: 1.07rem;
    }
    @media screen and (min-width: 1400px) {
      flex-direction: row;
      gap: 1.5rem;
    }
    @media screen and (min-width: 1700px) {
      flex-direction: row;
      gap: 2rem;
    }
  }
  // .privacyLogoContainer {
  //   img {
  //     width: 100%;
  //     aspect-ratio: 16/9;
  //   }
  // }
  .mediaIconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    a {
      z-index: 1;
    }

    @media screen and (min-width: 550px) {
      gap: 1rem;
    }

    @media screen and (min-width: 730px) {
      gap: 1.14rem;
    }

    @media screen and (min-width: 1000px) {
      gap: 1.4rem;
    }

    @media screen and (min-width: 1400px) {
      gap: 1.97rem;
    }

    @media screen and (min-width: 1700px) {
      gap: 2.62rem;
    }
  }
  .mediaIcons {
    display: flex;
    align-items: center;
    height: 0.628rem;
    z-index: 1;

    @media screen and (min-width: 730px) {
      height: 0.628rem;
    }

    @media screen and (min-width: 1000px) {
      height: 0.77rem;
    }

    @media screen and (min-width: 1400px) {
      height: 1.08rem;
    }

    @media screen and (min-width: 1700px) {
      height: 1.44rem;
    }
  }

  .copyright {
    color: #ffffff;
    font-size: 0.543rem;
    font-weight: 500;

    @media screen and (min-width: 730px) {
      font-size: 0.543rem;
    }

    @media screen and (min-width: 1000px) {
      font-size: 0.66rem;
    }

    @media screen and (min-width: 1400px) {
      font-size: 0.93rem;
    }

    @media screen and (min-width: 1700px) {
      font-size: 1.25rem;
    }
  }
}

.keepPrivateLogoContainer {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
.keepPrivateLogo {
  height: 4.4rem;

  @media screen and (min-width: 730px) {
    height: 4.46rem;
  }

  @media screen and (min-width: 1000px) {
    height: 5.47rem;
  }

  @media screen and (min-width: 1400px) {
    height: 7.7rem;
  }
  @media screen and (min-width: 1700px) {
    height: 10rem;
  }
}
.linksAndMediaContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.horizontalLine {
  display: flex;
  margin: 2.94rem 0 1.16rem;
  border: 1px solid #626f86;

  @media screen and (min-width: 730px) {
    margin: 2.9rem 0 1.16rem;
  }

  @media screen and (min-width: 1000px) {
    margin: 3.56rem 0 1.42rem;
  }

  @media screen and (min-width: 1400px) {
    margin: 5rem 0 2rem;
  }
  @media screen and (min-width: 1700px) {
    // margin: 3.83rem 0 2.67rem;
  }
}
.linksSectionContainer{
  display: flex;
  flex-direction: column;
}