.layoutContainer {
  width: 100%;
  max-width: 2500px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 2500px;
}

  
  .profileLayout {
    padding: 0 !important;
  }
  