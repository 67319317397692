.notFound{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 2rem;
    padding: 5rem 2rem;

    
    
    @media screen and (min-width: 730px) {
        padding: 10.5rem 3rem;
        
    }
    @media screen and (min-width: 1000px) {
        padding: 10.5rem 5rem;
        
    }
    @media screen and (min-width: 1400px) {
        padding: 10.5rem;
    }
    @media screen and (min-width: 1700px) {
        padding: 10.5rem 18.75rem;
    }


    .title{
        font-weight: 700;
        font-family: "Plus Jakarta Sans";
        font-size: clamp(1.5rem, 4vw + 1rem, 3.5rem );
    }

    .description{
        font-weight: 500;
        font-family: "Inter";
        font-size: clamp(.75rem, 1.2vw + 1rem, 1.2rem );
    }

    button{
        font-weight: 500;
        font-family: "Inter";
        border: none;
        border-radius: .5rem;
        background: #e9205d;
        color: white;
        padding: .7rem 1rem;
        font-size: clamp(.75rem, 1vw + 1rem, 1.2rem );
    }
}