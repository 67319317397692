.navbar {
  width: calc(100% - 1rem * 2);
  max-width: 2500px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  z-index: 11;
  margin-top: 1.88rem;
  border-radius: 0.75rem;
  background: rgba(255, 255, 255, 0.7);
  overflow: hidden;
  backdrop-filter: blur(8px);
  left: 50%;
  transform: translateX(-50%);

  @media screen and (min-width: 730px) {
    width: calc(100% - 2.25rem * 2);
    background: rgba(255, 255, 255, 0.7);
    border: 1px solid #e7ebef;
    // border-radius: 50rem;
    backdrop-filter: blur(12px);
    margin-top: 1.16rem;
  }
  @media screen and (min-width: 1000px) {
    width: calc(100% - 2.67rem * 2);
    margin-top: 1.42rem;
    backdrop-filter: blur(21px);
  }

  @media screen and (min-width: 1200px) {
    border-radius: 62rem;
  }

  @media screen and (min-width: 1400px) {
    margin-top: 2rem;
    width: calc(100% - 10.5rem * 2);
    background: none;
    border-radius: 62rem;
  }
  @media screen and (min-width: 1700px) {
    width: calc(100% - 18.75rem * 2);
  }

  .navbarChildContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: auto;
    width: 100%;
    background: #fff;
    backdrop-filter: blur(8px);
    // border-radius: 6rem;
    border-radius: 0.75rem;
    padding: 0.5rem 1rem;

    @media screen and (min-width: 730px) {
      padding: 0.75rem .75rem;
      background: rgba(255, 255, 255, 0.7);
      backdrop-filter: blur(12px);

    }
    @media screen and (min-width: 1000px) {
      padding: .875rem ;
      backdrop-filter: blur(21px);
      // border-radius: 62rem;
    }
    @media screen and (min-width: 1400px) {
     
    }
    @media screen and (min-width: 1700px) {
    }
  }
  .navOptions {
    display: flex;
    align-items: center;
    // gap: 1.14rem;
    gap: 2rem;
    height: auto;
    width: auto;
    justify-content: flex-end;

    .navLinks {
      display: flex;
      align-items: center;
      gap: 0.8rem;

      @media screen and (min-width: 1400px) {
        gap: 2rem;
      }

      .navOption {
        color: #0b0a0a;
        position: relative;
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 0.9rem;
        font-family: "Inter";

        @media screen and (min-width: 1000px) {
          padding: 0.27rem 0.33rem;
        }
        @media screen and (min-width: 1400px) {
          font-size: 1rem;
          padding: 0.25rem 0.75rem;
        }

        @media screen and (min-width: 1700px) {
        }

        p {
          display: flex;
          align-items: center;
          cursor: pointer;
          gap: 0.4rem;

          svg {
            height: 1rem;

            path {
              stroke: #444343;
            }
          }
        }

        .navOptionActive {
          color: #e9205d;

          svg {
            transform: rotate(-180deg);

            path {
              stroke: #e9205d;
            }
          }
        }
      }

      .navOption:hover {
        color: #e9205d;
      }
    }
  }

  .demo_button_container {
    a {
      height: 100%;
    }

    width: max-content;
    display: flex;
    align-items: center;
    gap: 1rem;
    width: auto;
    height: auto;

    @media screen and (min-width: 730px) {
      gap: 1.14rem;
    }
    @media screen and (min-width: 1000px) {
      gap: 1.4rem;
    }
  }
}

.logoContainer {
  display: flex;
  align-items: center;
  cursor: pointer;

  a {
    display: flex;
  }

  .logo {
    height: 1.5rem;

    @media screen and (min-width: 730px) {
      height: 1.14rem;
    }

    @media screen and (min-width: 1000px) {
      height: 1.39rem;
    }

    @media screen and (min-width: 1400px) {
      height: 2rem;
    }
  }
}

.hamIcon {
  display: flex;
  flex-flow: column nowrap;
  height: 1.3rem;
  justify-content: space-around;
  width: 1.3rem;
}

.navbar_burger {
  background-color: black;
  border-radius: 10px;
  height: 0.2rem;
  transform-origin: 2.5px;
  transition: all 0.3s linear;
  width: 1.3rem;
}
  .burger_active1 {
    transform: rotate(45deg);
  }

  .burger_active2 {
    transform: rotate(-45deg);
  }


.sideMenuContainer {
  position: relative;
  width: 100%;
  display: flex; 
  justify-content: center;
  // overflow-y: auto;
  z-index: 6;
  transition: all 0.4s linear;

  hr {
    border-top: 1px solid #f2f2f2;
  }

  .sideMenuWrapper {
    width: 100%;
  }

  .sideMenu {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
  }
  
  .sideMenuActive {
    width: 100%;
  }

  .menu {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  
    .menuHeading {
      color: black;
      font-weight: 500;
      font-size: 1.25rem;
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }
}
